import React, {createContext, ReactNode, SetStateAction, useContext, useEffect, useState} from 'react';
import {IConsumableTypes, IPostConsumables, IVehicleType, VehiclesApi} from "../../api/vehiclesApi";
import {IVehicle} from "../../api/authApi";
import {InventoryApi} from "../../api/inventoryApi";
import Cookies from "js-cookie";

interface AppContextType {
    isAuthenticated: boolean;
    updateAuthentication: () => void;
    formData: any;
    setFormData: React.Dispatch<React.SetStateAction<any>>;
    handleGetVehicleTypes: () => unknown;
    vehicleTypes: IVehicleType[];
    setVehicleTypes: React.Dispatch<React.SetStateAction<IVehicleType[]>>;
    consumableTypes: IConsumableTypes[];
    setConsumableTypes: React.Dispatch<SetStateAction<IConsumableTypes[]>>;
    consumableForm: IPostConsumables[];
    setConsumableForm: React.Dispatch<React.SetStateAction<IPostConsumables[]>>;
    files: { [key: string]: File | null };
    setFiles: React.Dispatch<React.SetStateAction<{ [key: string]: File | null }>>;
    fileNames: { [key: string]: string | null };
    setFileNames: React.Dispatch<React.SetStateAction<{ [key: string]: string | null }>>;
    handleGatheredData: () => unknown;
    isAllUploadsSuccessful: boolean;
    setIsAllUploadsSuccessful: React.Dispatch<React.SetStateAction<boolean>>;
    activeVehicle: IVehicle | null;
    setActiveVehicle: React.Dispatch<SetStateAction<IVehicle | null>>;
    handleGetVehicleById: (id: number) => unknown;
    isEditing: boolean;
    setIsEditing: React.Dispatch<SetStateAction<boolean>>;
    driverId: number | string;
    setDriverId: React.Dispatch<SetStateAction<number | string>>;
    dispatchesCount: number;
    expiringCount: number;
    role: string | null;
    setRole: React.Dispatch<SetStateAction<string | null>>;
}

const AppContext = createContext<AppContextType | undefined>(undefined);

export const AppProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [isAuthenticated, setIsAuthenticated] = useState(!!Cookies.get('access_token'));

    const vehicleApi = new VehiclesApi();
    const inventoryApi = new InventoryApi();
    const [formData, setFormData] = useState<any>({});
    const [activeVehicle, setActiveVehicle] = useState<IVehicle | null>(null);
    const [vehicleTypes, setVehicleTypes] = useState<IVehicleType[]>([])
    const [consumableTypes, setConsumableTypes] = useState<IConsumableTypes[]>([])
    const [isAllUploadsSuccessful, setIsAllUploadsSuccessful] = useState<boolean>(false)
    const [isEditing, setIsEditing] = useState<boolean>(false);
    const [driverId, setDriverId] = useState<number | string>('');
    const [dispatchesCount, setDispatchesCount] = useState<number>(0);
    const [expiringCount, setExpiringCount] = useState<number>(0);
    const [role, setRole] = useState<string | null>(null);

    const [files, setFiles] = useState<{ [key: string]: File | null }>({
        face: null,
        registration: null,
        technical: null,
    });
    const [fileNames, setFileNames] = useState<{ [key: string]: string | null }>({
        face: null,
        registration: null,
        technical: null,
    });
    const [consumableForm, setConsumableForm] = useState<IPostConsumables[]>([
        {
            consumable_type_id: null,
            consumption_rate: null,
            consumption_unit: '',
            consumption_rate_calculation_interval: null,
            vehicle_consumable_volume: null,
            replacement_interval: null,
            replacement_interval_unit: '',
            replacement_part_id: null,
        },
    ])

    const updateAuthentication = () => {
        setIsAuthenticated(!!Cookies.get('access_token'));
    };

    const handleGetVehicleTypes = async () => {
        const response = await vehicleApi.getVehicleTypes();
        setVehicleTypes(response);
        const data = await vehicleApi.getConsumablesTypes();
        setConsumableTypes(data);
    }
    const handleGatheredData = async () => {
        let updatedFormData = { ...formData };
        if (fileNames.face) {
            updatedFormData.doc_front_image = fileNames.face;
        }
        if (fileNames.registration) {
            updatedFormData.doc_registration = fileNames.registration;
        }
        if (fileNames.technical) {
            updatedFormData.doc_tech_details = fileNames.technical;
        }
        if (fileNames.doc_insurance) {
            updatedFormData.doc_insurance = fileNames.doc_insurance;
        }
        try {
            if(isEditing){
                void vehicleApi.putVehicle(updatedFormData, Number(activeVehicle?.id));
                void vehicleApi.putConsumables(consumableForm, Number(activeVehicle?.id));
                // need to update endpoint to receive id in url and to accept array
            } else {
                const vehicle = await vehicleApi.postVehicle(updatedFormData);
                const vehicleId = vehicle.id;
                await vehicleApi.postConsumables(consumableForm, Number(vehicleId));
            }
        } catch (error) {
            console.error('Error posting data:', error);
        }
    };

    const handleGetVehicleById = async (id: number) => {
        try {
            const data = await vehicleApi.getVehicleById(id);
            setActiveVehicle(data);
            setFormData(data);
            setFileNames({face: data.doc_front_image, registration: data.doc_registration, technical: data.doc_tech_details, doc_insurance: data.doc_insurance })
            const response = await vehicleApi.getConsumablesById(id);
            setConsumableForm(response);
            if(data) {
                setIsEditing(true);
            }
        } catch (error) {
            console.error("Error fetching vehicle data:", error);
        }
    };

    useEffect(() => {
        const handleGetDispatchesCount = async () => {
            try {
                const data = await inventoryApi.getDispatchesCount();
                setDispatchesCount(data);
            } catch (error) {
                console.error('Failed to fetch dispatches count:', error);
            }
        };
        handleGetDispatchesCount();
    }, []);

    useEffect(() => {
        const handleGetExpiringCount = async  () => {
            try {
                const data = await  vehicleApi.getListNumberOfExpiringDocs();
                setExpiringCount(data);
            } catch (e) {
                console.log(e);
            }
        }
        if (isAuthenticated) {
            handleGetExpiringCount();
        }
    }, [isAuthenticated]);

    useEffect(() => {
        updateAuthentication();
    }, []);

    return (
        <AppContext.Provider value={{
            isAuthenticated,
            updateAuthentication,
            activeVehicle,
            setActiveVehicle,
            formData,
            setFormData,
            handleGetVehicleTypes,
            setVehicleTypes,
            vehicleTypes,
            consumableTypes,
            setConsumableTypes,
            consumableForm,
            setConsumableForm,
            files,
            setFiles,
            fileNames,
            setFileNames,
            handleGatheredData,
            isAllUploadsSuccessful,
            setIsAllUploadsSuccessful,
            handleGetVehicleById,
            isEditing,
            setIsEditing,
            driverId,
            setDriverId,
            dispatchesCount,
            expiringCount,
            role,
            setRole
        }}>
            {children}
        </AppContext.Provider>
    );
};

export const useAppContext = () => {
    const context = useContext(AppContext);
    if (context === undefined) {
        throw new Error('useAppContext must be used within an AppProvider');
    }
    return context;
};
