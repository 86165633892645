import React from "react";
import { Modal, Box, Typography, Button } from "@mui/material";

interface DeletePartModalProps {
    open: boolean;
    onClose: () => void;
    onDelete: () => void;
    partName?: string; // Optional part name for displaying in the confirmation
}

const DeletePartModal: React.FC<DeletePartModalProps> = ({ open, onClose, onDelete, partName }) => {
    return (
        <Modal open={open} onClose={onClose}>
            <Box
                sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    width: 400,
                    bgcolor: "background.paper",
                    p: 3,
                    borderRadius: 2,
                    textAlign: "center",
                }}
            >
                <Typography variant="h6" mb={2}>
                    Удалить часть заявки
                </Typography>
                <Typography mb={3}>
                    Вы уверены, что хотите удалить{" "}
                    {partName ? `"${partName}"` : "эту часть"}?
                </Typography>
                <Box display="flex" justifyContent="space-between">
                    <Button variant="outlined" onClick={onClose}>
                        Отмена
                    </Button>
                    <Button variant="contained" color="error" onClick={onDelete}>
                        Удалить
                    </Button>
                </Box>
            </Box>
        </Modal>
    );
};

export default DeletePartModal;
