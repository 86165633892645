import React, {useEffect, useState} from 'react';
import {Link, useLocation, useNavigate, useParams} from "react-router-dom";
import {IApplication, IApplicationHistory, IApplicationPart, RegisterApi} from "../../api/registerApi";
import {
    Box,
    Button,
    Card,
    CardContent, Divider,
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import useModal from "../VehiclesPage/modals/useModal";
import {Delete, Edit} from "@mui/icons-material";
import EditPartModal from "./modals/EditPartModal";
import DeletePartModal from "./modals/DeleteLinkedPartModal";
import {toast} from "react-toastify";
import {useAppContext} from "../store/AppContext";
import ApplicationHistory from "./components/ApplicationHistory";
import ApplicationHistoryModal from "./modals/ApplicationHistoryModal";
import ApplicationCard from "./components/ApplicationCard";

//todo: will need updates after backend fixes
const ApplicationPartsPage = () => {
    const {id} = useParams();
    const location = useLocation();
    const registerApi = new RegisterApi();
    const [application, setApplication] = useState<IApplication | null>(null);
    const [applicationHistory, setApplicationHistory] = useState<IApplicationHistory[]>([]);
    const navigate = useNavigate();
    const { isOpen, openModal, closeModal } = useModal();
    const [selectedPart, setSelectedPart] = useState<IApplicationPart | null>(null);
    const {role} = useAppContext();

    const handleGetApplication = async() => {
        try {
            const data = await registerApi.getApplicationById(Number(id));
            setApplication(data);
        } catch (e) {
            console.log(e);
        }
    }

    const handleEditPart = (part: IApplicationPart) => {
        setSelectedPart(part);
        openModal("editPart");
    };

    const handleDeletePart = (part: IApplicationPart) => {
        setSelectedPart(part);
        openModal("deletePart");
    };

    useEffect(() => {
        void handleGetApplication();
    },[])

    const handleSavePart = async (partData: any) => {
        try {
            if (partData.id) {
                await registerApi.putApplicationPartById(application?.id as number, partData.id, partData);
                toast.success("Часть успешно обновлена!");
            } else {
                await registerApi.postApplicationParts(application?.id as number, {...partData, type: 'Склад'});
            }
        } catch (error) {
            console.error("Ошибка:", error);
        } finally {
            closeModal("editPart");
            setSelectedPart(null);
            void handleGetApplication();
        }
    };

    const handleConfirmDeletePart = async () => {
        try {
            if (selectedPart?.id) {
                await registerApi.deleteApplicationPartById(application?.id as number, selectedPart.id);
                toast.success("Часть успешно удалена!");
            }
        } catch (error) {
            console.error("Ошибка:", error);
        } finally {
            closeModal("deletePart");
            setSelectedPart(null);
            void handleGetApplication();
        }
    };

    const handleGetApplicationHistory = async () => {
        try {
            const data = await registerApi.getApplicationHistory(Number(id));
            setApplicationHistory(data);
        } catch (e) {
            console.log(e);
        }
    }

    useEffect(() => {
        void handleGetApplicationHistory();
    }, []);

    return (
        <Box>
            <Box sx={{mb:2}} display={'flex'} justifyContent={"space-between"}>
                <Box>
                    <IconButton onClick={() => navigate('/register')}>
                        <ArrowBackIcon />
                    </IconButton>
                </Box>
                {role === 'Инициатор' && <Button variant={'contained'} onClick={() => {
                    setSelectedPart(null);
                    openModal('editPart');
                }}>
                    Добавить части заявки
                </Button>}
            </Box>
            <ApplicationCard application={application as IApplication} openHistory={() => openModal("history")}/>
            <Typography variant="h6" gutterBottom>
                Части заявки
            </Typography>
            <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                      <TableRow>
                          <TableCell>№</TableCell>
                          <TableCell>Название</TableCell>
                          <TableCell>ТС</TableCell>
                          <TableCell>Количество</TableCell>
                          <TableCell>Дата</TableCell>
                          <TableCell>Описание</TableCell>
                          <TableCell>Комментарии</TableCell>
                          <TableCell>Действия</TableCell>
                      </TableRow>
                  </TableHead>
                    <TableBody>
                        {application?.linked_application_parts.length !== 0 ? application?.linked_application_parts?.map((part,idx) => (
                            <TableRow onClick={()=> navigate(`${location.pathname}/${part.id}`)}>
                                <TableCell>{idx + 1}</TableCell>
                                <TableCell>{part.name}</TableCell>
                                <TableCell onClick={(e)=> e.stopPropagation()}>
                                    <Link to={`/vehicle-info/${part?.vehicle?.id}`}>
                                        {part?.vehicle?.garage_number}
                                    </Link>
                                </TableCell>
                                <TableCell>{part.quantity}</TableCell>
                                <TableCell>{new Date(part.created_at).toLocaleDateString()}</TableCell>
                                <TableCell>{part.description}</TableCell>
                                <TableCell>{part.comments}</TableCell>
                                <TableCell>
                                    <IconButton
                                        size="small"
                                        color="primary"
                                        onClick={(e) => {handleEditPart(part); e.stopPropagation()}}
                                    >
                                        <Edit />
                                    </IconButton>
                                    <IconButton
                                        size="small"
                                        color="error"
                                        onClick={(e) => {handleDeletePart(part); e.stopPropagation()}}
                                    >
                                        <Delete />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        )) :
                            <Typography color="text.secondary" p={2}>
                                Нет частей для этой заявки
                            </Typography>
                        }
                    </TableBody>
                </Table>
            </TableContainer>
            <EditPartModal
                open={isOpen["editPart"]}
                onClose={() => {
                    closeModal("editPart");
                    setSelectedPart(null);
                }}
                onSave={handleSavePart}
                initialData={selectedPart}
                mode={selectedPart?.id ? "edit" : "create"}
            />
            <DeletePartModal
                open={isOpen["deletePart"]}
                onClose={() => {
                    closeModal("deletePart");
                    setSelectedPart(null);
                }}
                onDelete={handleConfirmDeletePart}
                partName={selectedPart?.name}
            />
            <ApplicationHistoryModal
                open={isOpen["history"]}
                onClose={()=> {
                    closeModal("history");
                }}
                children={applicationHistory.length !== 0 ? <ApplicationHistory history={applicationHistory}/> : <Typography>История не найдена!</Typography> }
            />
        </Box>
    );
};

export default ApplicationPartsPage;