import React, {useContext, useState} from 'react';
import {Box} from "@mui/material";
import RoleSelection from "./RoleSelection";
import RegisterApplications from "./RegisterApplications";
import {useAppContext} from "../store/AppContext";

const RegisterMain = () => {
    const {role, setRole} = useAppContext();
    return (
        <Box>
            {!role && (
                <RoleSelection
                    open={!role}
                    onClose={(selectedRole) => {
                        if (selectedRole) {
                            setRole(selectedRole);
                        }
                    }}
                />
            )}
            {role && <RegisterApplications/>}
        </Box>
    );
};

export default RegisterMain;