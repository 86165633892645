import React, { useState } from 'react';
import {
    Box,
    Button,
    Modal,
    TextField,
    Typography,
} from '@mui/material';

interface CommentAcceptModalProps {
    open: boolean;
    onClose: () => void;
    onConfirm: (comment: string) => void;
}

const CommentAcceptModal:React.FC<CommentAcceptModalProps> = ({ open, onClose, onConfirm }) => {
    const [comment, setComment] = useState('');

    const handleConfirm = () => {
        onConfirm(comment);
        setComment('');
        onClose();
    };

    return (
        <Modal
            open={open}
            onClose={onClose}
            aria-labelledby="modal-title"
            aria-describedby="modal-description"
        >
            <Box
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 400,
                    bgcolor: 'background.paper',
                    border: '2px solid #000',
                    boxShadow: 24,
                    p: 4,
                    borderRadius: 2,
                }}
            >
                <Typography id="modal-title" variant="h6" component="h2" gutterBottom>
                    Подтверждение поставщика
                </Typography>
                <TextField
                    id="modal-description"
                    label="Комментарии"
                    multiline
                    rows={4}
                    fullWidth
                    value={comment}
                    onChange={(e) => setComment(e.target.value)}
                    variant="outlined"
                />
                <Box sx={{ mt: 3, display: 'flex', justifyContent: 'space-between' }}>
                    <Button variant="outlined" onClick={onClose}>
                        Отменить
                    </Button>
                    <Button variant="contained" onClick={handleConfirm}>
                        Подтвердить
                    </Button>
                </Box>
            </Box>
        </Modal>
    );
};

export default CommentAcceptModal;
