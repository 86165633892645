import React from "react";
import { Box, Typography, Avatar } from "@mui/material";
import { IApplicationHistory } from "../../../api/registerApi";

interface ApplicationHistoryListProps {
    history: IApplicationHistory[];
}

const ApplicationHistory: React.FC<ApplicationHistoryListProps> = ({
                                                                            history,
                                                                        }) => {

    const getLineColor = (verdict: string) => {
        if (verdict === "Санкционировано") return "#4caf50"; // Green
        if (verdict === "Отправлено на доработку") return "#f44336"; // Red
        return "#9e9e9e"; // Default (Gray)
    };

    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                gap: 2,
                p: 2,
                maxHeight: "600px",
                overflowY: "auto",
                border: "1px solid #e0e0e0",
                borderRadius: "8px",
            }}
        >
            {history.map((item, index) => (
                <Box
                    key={item.id}
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: 2,
                        position: "relative",
                    }}
                >
                    {/* Line connecting metro stations */}
                    {index !== history.length - 1 && (
                        <Box
                            sx={{
                                position: "absolute",
                                left: "20px",
                                top: "50%",
                                height: "calc(100% + 16px)",
                                width: "4px",
                                backgroundColor: getLineColor(item.verdict),
                                zIndex: -1,
                            }}
                        />
                    )}

                    {/* Metro station (circle avatar) */}
                    <Avatar
                        sx={{
                            bgcolor: getLineColor(item.verdict),
                            color: "#fff",
                            width: 40,
                            height: 40,
                            fontSize: 16,
                        }}
                    />
                    {/* Details */}
                    <Box
                        sx={{
                            backgroundColor: "#f5f5f5",
                            borderRadius: "8px",
                            p: 2,
                            boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
                            flexGrow: 1,
                        }}
                    >
                        <Typography variant="body2">
                            <strong>Этап:</strong> {item.stage}
                        </Typography>
                        <Typography variant="body2">
                            <strong>Вердикт:</strong> {item.verdict}
                        </Typography>
                        <Typography variant="body2">
                            <strong>Дата окончания:</strong>{" "}
                            {item.end_date && new Date(item.end_date).toLocaleString()}
                        </Typography>
                        <Typography variant="body2">
                            <strong>Комментарий:</strong> {item.comments}
                        </Typography>
                    </Box>
                    {/* Date on the right */}
                    <Typography
                        variant="body2"
                        sx={{
                            alignSelf: "flex-start",
                            color: "gray",
                            whiteSpace: "nowrap",
                            fontWeight: 600,
                        }}
                    >
                        {new Date(item.created_at).toLocaleString()}
                    </Typography>
                </Box>
            ))}
        </Box>
    );
};

export default ApplicationHistory;
