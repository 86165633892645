import React from "react";
import {Box, Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography} from "@mui/material";

interface ApplicationHistoryModalProps {
    open: boolean;
    onClose: () => void;
    title?: string;
    children?: React.ReactNode;
}

const ApplicationHistoryModal: React.FC<ApplicationHistoryModalProps> = ({
                                                                             open,
                                                                             onClose,
                                                                             title = "История заявки",
                                                                             children,
                                                                         }) => {
    return (
        <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
                {children ? <Box>{children}</Box> : <Typography>История не найдена!</Typography>}
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary">
                    Закрыть
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ApplicationHistoryModal;
