import React, { FC, ReactNode } from 'react';
import { Box, Button, Modal, Typography } from '@mui/material';

interface ConfirmationModalProps {
    open: boolean;
    title: string;
    content: string;
    onClose: () => void;
    onConfirm: () => void;
    confirmText?: string; // Optional confirm button text
    cancelText?: string; // Optional cancel button text
}

const UniversalDeleteModal: FC<ConfirmationModalProps> = ({
                                                           open,
                                                           title,
                                                           content,
                                                           onClose,
                                                           onConfirm,
                                                           confirmText = 'Подтвердить',
                                                           cancelText = 'Отменить',
                                                       }) => {
    return (
        <Modal
            open={open}
            onClose={onClose}
            aria-labelledby="modal-title"
            aria-describedby="modal-description"
        >
            <Box
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 400,
                    bgcolor: 'background.paper',
                    border: '2px solid #000',
                    boxShadow: 24,
                    p: 4,
                    borderRadius: 2,
                }}
            >
                <Typography id="modal-title" variant="h6" gutterBottom>
                    {title}
                </Typography>
                <Typography id="modal-description" variant="body1" gutterBottom>
                    {content}
                </Typography>
                <Box sx={{ mt: 3, display: 'flex', justifyContent: 'space-between' }}>
                    <Button variant="outlined" onClick={onClose}>
                        {cancelText}
                    </Button>
                    <Button variant="contained" color="primary" onClick={onConfirm}>
                        {confirmText}
                    </Button>
                </Box>
            </Box>
        </Modal>
    );
};

export default UniversalDeleteModal;
