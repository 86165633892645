import React, {useState} from 'react';
import {Box, Button, MenuItem, Modal, Select, TextField, Typography} from '@mui/material';


interface ConfirmSanctionModalProps {
    open: boolean; // Controls modal visibility
    onClose: () => void; // Function to close the modal
    onConfirm: (selectedRole: string, comment: string) => void; // Function to return selected values
    isPurchasing: boolean;
}

const ConfirmSanctionModal: React.FC<ConfirmSanctionModalProps> = ({ open, onClose, onConfirm, isPurchasing = false }) => {
    const [selectedRole, setSelectedRole] = useState<string>('');
    const [comment, setComment] = useState<string>('');

    const handleClose = () => {
        setSelectedRole('');
        setComment('');
        onClose();
    }

    const handleConfirm = () => {
        onConfirm(selectedRole, comment); // Pass values to the parent
        handleClose(); // Close the modal
    };

    return (
        <Modal open={open} onClose={handleClose}>
            <Box
                sx={{
                    width: 400,
                    p: 4,
                    borderRadius: 2,
                    boxShadow: 24,
                    bgcolor: 'background.paper',
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                }}
            >
                {isPurchasing &&
                <>
	                <Typography variant="h6" mb={2}>
		                Выберите стадию для санкционирования
	                </Typography>
	                <Select
			                fullWidth
			                value={selectedRole}
			                onChange={(e) => setSelectedRole(e.target.value)}
			                sx={{ mb: 2 }}
	                >
		                <MenuItem value={'Юрист'}>
			                Юрист
		                </MenuItem>
		                <MenuItem value={'Контроль затрат'}>
			                Контроль затрат
		                </MenuItem>
		                <MenuItem value={'Бухгалтерия'}>
			                Бухгалтерия
		                </MenuItem>
	                </Select>
                </>
                }
                <TextField
                    fullWidth
                    multiline
                    rows={3}
                    placeholder="Комментарии"
                    value={comment}
                    onChange={(e) => setComment(e.target.value)}
                    sx={{ mb: 3 }}
                />
                <Box display="flex" justifyContent="space-between">
                    <Button variant="outlined" onClick={handleClose}>
                        Отменить
                    </Button>
                    <Button variant="contained" color="primary" onClick={handleConfirm}>
                        Подтвердить
                    </Button>
                </Box>
            </Box>
        </Modal>
    );
};

export default ConfirmSanctionModal;
