import React, { useState } from 'react';
import { Select, MenuItem, Box, Typography, Button, TextField, Modal } from '@mui/material';
import { roles } from '../RoleSelection';

interface RevertConfirmModalProps {
    open: boolean; // Controls modal visibility
    onClose: () => void; // Function to close the modal
    onConfirm: (selectedRole: string, comment: string) => void; // Function to return selected values
}

const RevertConfirmModal: React.FC<RevertConfirmModalProps> = ({ open, onClose, onConfirm }) => {
    const [selectedRole, setSelectedRole] = useState<string>('');
    const [comment, setComment] = useState<string>('');

    const handleClose = () => {
        setSelectedRole('');
        setComment('');
        onClose();
    }

    const handleConfirm = () => {
        onConfirm(selectedRole, comment); // Pass values to the parent
        handleClose(); // Close the modal
    };

    return (
        <Modal open={open} onClose={handleClose}>
            <Box
                sx={{
                    width: 400,
                    p: 4,
                    borderRadius: 2,
                    boxShadow: 24,
                    bgcolor: 'background.paper',
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                }}
            >
                <Typography variant="h6" mb={2}>
                    Выберите стадию для возврата
                </Typography>
                <Select
                    fullWidth
                    value={selectedRole}
                    onChange={(e) => setSelectedRole(e.target.value)}
                    sx={{ mb: 2 }}
                >
                    {roles.map((role) => (
                        <MenuItem key={role.key} value={role.key}>
                            {role.label}
                        </MenuItem>
                    ))}
                </Select>
                <TextField
                    fullWidth
                    multiline
                    rows={3}
                    placeholder="Комментарии"
                    value={comment}
                    onChange={(e) => setComment(e.target.value)}
                    sx={{ mb: 3 }}
                />
                <Box display="flex" justifyContent="space-between">
                    <Button variant="outlined" onClick={handleClose}>
                        Отменить
                    </Button>
                    <Button variant="contained" color="primary" onClick={handleConfirm}>
                        Подтвердить
                    </Button>
                </Box>
            </Box>
        </Modal>
    );
};

export default RevertConfirmModal;
