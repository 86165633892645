import React, {useContext, useState} from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    RadioGroup,
    FormControlLabel,
    Radio,
} from '@mui/material';
import {useAppContext} from "../store/AppContext";

interface RoleSelectionDialogProps {
    open: boolean;
    onClose: (selectedRole: string | null) => void;
}

// Define roles with keys (left side) and display names (right side)
export const roles = [
    { key: 'Инициатор', label: 'Инициатор' },
    { key: 'Склад', label: 'Склад' },
    { key: 'Проектный менеджер', label: 'Проектный менеджер' },
    { key: 'Отдел закупок', label: 'Отдел закупок' },
    { key: 'Юрист', label: 'Юрист' },
    { key: 'Технический директор', label: 'Технический директор' },
    { key: 'Контроль затрат', label: 'Контроль затрат' },
    { key: 'Директор', label: 'Директор' },
    { key: 'Бухгалтерия', label: 'Бухгалтерия' },
];

const RoleSelectionDialog: React.FC<RoleSelectionDialogProps> = ({ open, onClose }) => {
    const {role, setRole} = useAppContext();

    const handleConfirm = () => {
        if (role) {
            console.log(`Selected role key: ${role}`);
            onClose(role);
        }
    };

    return (
        <Dialog open={open}>
            <DialogTitle>Выберите роль</DialogTitle>
            <DialogContent>
                <RadioGroup value={role} onChange={(e) => setRole(e.target.value)}>
                    {roles.map((role) => (
                        <FormControlLabel
                            key={role.key}
                            value={role.key}
                            control={<Radio />}
                            label={role.label}
                        />
                    ))}
                </RadioGroup>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => onClose(null)} color="secondary">
                    Отмена
                </Button>
                <Button onClick={handleConfirm} color="primary" disabled={!role}>
                    Подтвердить
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default RoleSelectionDialog;
