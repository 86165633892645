import React, {ChangeEvent, useEffect, useState} from 'react';
import {
    Box,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Modal,
    Typography
} from '@mui/material';
import {RegisterApi} from "../../../api/registerApi";
import {VehiclesApi} from "../../../api/vehiclesApi";
import {toast} from "react-toastify";

// Define the props for the DocumentModal component
interface DocumentModalProps {
    isOpen: boolean;
    onClose: () => void;
    documentData: {
        id: number;
        type: string;
        docId?: number;
    } | null;
    reFetch?: () => void;
}

// DocumentModal Component
const DocumentModal: React.FC<DocumentModalProps> = ({ isOpen, onClose, documentData, reFetch }) => {
    const [preview, setPreview] = useState<string | null>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState<boolean>(false);
    const [documentId, setDocumentId] = useState<number | null>(null);
    const registerApi = new RegisterApi();
    const vehiclesApi = new VehiclesApi();

    useEffect(() => {
        if (documentData === null) {
            setPreview(null)
        } else {
            void fetchPreview();
        }
    }, [documentData]);

    // Fetch the document for preview
    const fetchPreview = async (): Promise<void> => {
        if (!documentData) {
            setPreview(null);
            return;
        }

        setLoading(true);
        try {
            const data = await registerApi.getDocument(documentData.id, documentData.type);
            let docId = documentData.docId ? documentData.docId : data[0].id;
            setDocumentId(docId);
            const response = await registerApi.downloadDoc(docId);
            // Extract filename from the Content-Disposition header
            const contentDisposition = response.headers['content-disposition'];
            let filename = 'downloaded_file'; // Default filename fallback

            if (contentDisposition) {
                const filenameMatch = contentDisposition.match(/filename\*=UTF-8''(.+)/i) || contentDisposition.match(/filename="?(.+)"?/);
                if (filenameMatch && filenameMatch[1]) {
                    filename = decodeURIComponent(filenameMatch[1].replace(/"/g, ''));
                }
            }

            // Extract file extension to determine MIME type
            const fileExtension = filename.split('.').pop()?.toLowerCase();
            let mimeType = 'application/octet-stream'; // Default MIME type

            if (fileExtension === 'pdf') {
                mimeType = 'application/pdf';
            }

            // Create a Blob with the corrected MIME type
            const blob = new Blob([response.data], { type: mimeType });

            // Generate a URL for the Blob
            const fileUrl = window.URL.createObjectURL(blob);

            // Set the URL for preview
            setPreview(fileUrl);
        } catch (error) {
            console.error("Error fetching document for preview:", error);
            setPreview(null);
        } finally {
            setLoading(false);
        }
    };

    // Handle file upload
    const handleFileUpload = async (e: ChangeEvent<HTMLInputElement>): Promise<void> => {
        const selectedFile = e.target.files?.[0];
        if (!selectedFile || !documentData) return;
        setLoading(true);
        try {
            const formData = new FormData();
            formData.append("file", selectedFile);
            const docId = await vehiclesApi.postVehicleDocs(formData);
            await registerApi.postDocument({
                document_title: docId,
                document_type: documentData.type,
                document_path: docId,
                linked_object_id: documentData.id
            });
            // toast.success('Файл успешно загружен!');
            await new Promise((resolve) => setTimeout(resolve, 1000));
            await fetchPreview();
        } catch (error) {
            console.error("Error uploading file:", error);
        } finally {
            setLoading(false);
        }
    };

    // // Handle document download
    // const handleDownloadDoc = async (): Promise<void> => {
    //     if (!documentData) return;
    //
    //     try {
    //         await downloadFile({
    //             apiCall: () => registerApi.downloadDoc(documentData.id),
    //         });
    //     } catch (error) {
    //         console.error("Error downloading document:", error);
    //     }
    // };

    // Handle document deletion
    const handleDelete = async (): Promise<void> => {
        if (!documentData) return;
        setLoading(true);
        setShowDeleteConfirmation(false);
        try {
            await registerApi.deleteDocument(documentId as number);
            setPreview(null);
            if(documentData.docId) {
                onClose();
                reFetch?.();
            }
            toast.success('Файл успешно удалён!');
        } catch (error) {
            console.error("Error deleting document:", error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            {/* Main Modal */}
            <Modal open={isOpen} onClose={onClose}>
                <Box sx={{
                    padding: 4,
                    backgroundColor: 'white',
                    borderRadius: 2,
                    maxWidth: '1400px',
                    margin: 'auto',
                    marginTop: '5%',
                    height: '100%',
                    maxHeight: '90vh',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 2,
                }}>
                    <Typography variant="h6" display={'flex'} justifyContent={'center'}>Предпросмотр документа</Typography>
                    <Button onClick={() => setShowDeleteConfirmation(true)} color="secondary">
                        Удалить файл
                    </Button>
                    {loading ? (
                        <Box
                            sx={{
                                flexGrow: 1,
                                overflow: 'hidden',
                                borderRadius: 2,
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                        >
                            <CircularProgress />
                        </Box>
                    ) : (
                        <>
                            {preview ? (
                                <Box height={'100%'}>
                                    <iframe
                                        src={preview}
                                        width="100%"
                                        height="100%"
                                        title="Document Preview"
                                        style={{
                                            border: 'none',
                                            maxHeight: '100%',
                                            maxWidth: '100%',
                                        }}
                                    />
                                </Box>
                            ) : (
                                <Box mt={2} display="flex" alignItems="center" justifyContent="center" flexDirection="column" gap={2}>
                                    <Typography>
                                        Документ не найден
                                    </Typography>
                                    <label htmlFor="upload-file">
                                        <input
                                            id="upload-file"
                                            type="file"
                                            style={{ display: 'none' }}
                                            onChange={handleFileUpload}
                                        />
                                        <Button component="span" color="primary" variant="contained">
                                            Загрузить документ
                                        </Button>
                                    </label>
                                </Box>
                            )}
                        </>
                    )}
                </Box>
            </Modal>

            {/* Delete Confirmation Dialog */}
            <Dialog open={showDeleteConfirmation} onClose={() => setShowDeleteConfirmation(false)}>
                <DialogTitle>Подтвердить удаление</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Вы уверены, что хотите удалить этот документ? Это действие не может быть отменено.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setShowDeleteConfirmation(false)} color="primary">
                        Отмена
                    </Button>
                    <Button onClick={handleDelete} color="secondary">
                        Подтвердить
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default DocumentModal;
