import React, {useEffect, useState} from "react";
import {Box, Button, MenuItem, Modal, TextField, Typography,} from "@mui/material";
import {IProjects} from "../../../api/registerApi";

interface LinkedApplicationPart {
    name: string;
    description: string;
    quantity: number;
}

interface CreateApplicationModalProps {
    open: boolean;
    onClose: () => void;
    onSave: (applicationData: {
        id?:number;
        project_id: number;
        title: string;
        description: string;
    }) => void;
    projects: IProjects[];
    initialData?: any;
}

const CreateApplicationModal: React.FC<CreateApplicationModalProps> = ({
                                                                           open,
                                                                           onClose,
                                                                           onSave,
                                                                           projects,
                                                                           initialData,
                                                                       }) => {
    const [projectId, setProjectId] = useState<number>(projects[0]?.id || 0);
    const [title, setTitle] = useState<string>("");
    const [description, setDescription] = useState<string>("");

    useEffect(() => {
        if (open) {
            if (initialData?.id) {
                // If editing, populate fields with initialData
                setProjectId(initialData.project_id);
                setTitle(initialData.title);
                setDescription(initialData.description);

            } else {
                // Clear fields for new creation
                setProjectId(projects[0]?.id || 0);
                setTitle("");
                setDescription("");

            }
        } else {
            // Reset the fields when the modal is closed
            setProjectId(projects[0]?.id || 0);
            setTitle("");
            setDescription("");

        }
    }, [open, initialData, projects]);


    const handleSave = () => {
        if (title.trim() && projectId) {
            const payload = {
                project_id: projectId,
                title,
                description,
            };

            if (initialData?.id) {
                // Send to edit endpoint
                onSave({ ...payload, id: initialData.id});
            } else {
                // Send to create endpoint
                onSave(payload);
            }
            onClose(); // Close the modal after saving
        } else {
            alert("Название и проект обязательны для заполнения!");
        }
    };

    return (
        <Modal open={open} onClose={onClose}>
            <Box
                sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    width: "66%",
                    transform: "translate(-50%, -50%)",
                    bgcolor: "background.paper",
                    borderRadius: 4,
                    boxShadow: 24,
                    p: 4,
                }}
            >
                <Typography variant="h6" mb={2}>
                    {initialData?.id ? "Редактировать заявку" : "Создать заявку"}
                </Typography>
                <Box
                    sx={{
                        overflow: "auto",
                        maxHeight: "calc(100% - 120px)",
                        mb: 2,
                    }}
                >
                    <Box display="flex" flexDirection="column" gap={2}>
                        <TextField
                            select
                            label="Проект"
                            value={projectId}
                            onChange={(e) => setProjectId(Number(e.target.value))}
                            fullWidth
                            required
                        >
                            {projects.map((project) => (
                                <MenuItem key={project.id} value={project.id}>
                                    {project.name}
                                </MenuItem>
                            ))}
                        </TextField>
                        <TextField
                            label="Название"
                            value={title}
                            onChange={(e) => setTitle(e.target.value)}
                            fullWidth
                            required
                        />
                        <TextField
                            label="Описание"
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                            fullWidth
                            multiline
                            rows={3}
                        />
                        <Box display="flex" justifyContent="flex-end" gap={2} mt={2}>
                            <Button onClick={onClose} color="secondary" variant="outlined">
                                Отмена
                            </Button>
                            <Button onClick={handleSave} color="primary" variant="contained">
                                Сохранить
                            </Button>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Modal>
    );
};

export default CreateApplicationModal;
