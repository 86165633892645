import React, { useState, useEffect } from 'react';
import { Box, Button, Modal, TextField, Typography, MenuItem, Select } from '@mui/material';

interface InvoiceModalProps {
    open: boolean;
    onClose: () => void;
    onSubmit: (data: {
        candidate_offer_id: number;
        invoice_number: string;
        invoice_title: string;
        payment_amount: number;
        currency: string;
        payment_date_requested: string;
        payment_date_actual: string;
    }) => void;
    initialData?: {
        candidate_offer_id: number;
        invoice_number: string;
        invoice_title: string;
        payment_amount: number;
        currency: string;
        payment_date_requested: string;
        payment_date_actual: string;
    };
    candidateOfferId: number;
}

const InvoiceModal: React.FC<InvoiceModalProps> = ({
                                                       open,
                                                       onClose,
                                                       onSubmit,
                                                       initialData,
                                                       candidateOfferId,
                                                   }) => {
    const [formData, setFormData] = useState({
        candidate_offer_id: candidateOfferId,
        invoice_number: '',
        invoice_title: '',
        payment_amount: 0,
        currency: 'KZT',
        payment_date_requested: '',
        payment_date_actual: '',
    });

    const handleChange = (field: string) => (e: React.ChangeEvent<HTMLInputElement | { value: unknown }>) => {
        setFormData((prev) => ({
            ...prev,
            [field]: field === 'payment_amount' ? Number(e.target.value) : e.target.value,
        }));
    };

    const handleSubmit = () => {
        onSubmit(formData);
        onClose();
    };

    const handleClose = () => {
        setFormData({
            candidate_offer_id: candidateOfferId,
            invoice_number: '',
            invoice_title: '',
            payment_amount: 0,
            currency: 'KZT',
            payment_date_requested: '',
            payment_date_actual: '',
        });
        onClose();
    };

    useEffect(() => {
        if (initialData) {
            setFormData(initialData);
        } else {
            setFormData((prev) => ({
                ...prev,
                candidate_offer_id: candidateOfferId,
                currency: 'KZT', // Default value
            }));
        }
    }, [initialData, candidateOfferId, open]);

    return (
        <Modal open={open} onClose={handleClose}>
            <Box
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 400,
                    bgcolor: 'background.paper',
                    boxShadow: 24,
                    p: 4,
                    borderRadius: 2,
                }}
            >
                <Typography variant="h6" mb={2}>
                    {initialData ? 'Редактировать счет' : 'Создать счет'}
                </Typography>
                <TextField
                    fullWidth
                    label="Номер счета"
                    value={formData.invoice_number}
                    onChange={handleChange('invoice_number')}
                    sx={{ mb: 2 }}
                />
                <TextField
                    fullWidth
                    label="Название счета"
                    value={formData.invoice_title}
                    onChange={handleChange('invoice_title')}
                    sx={{ mb: 2 }}
                />
                <TextField
                    fullWidth
                    type="number"
                    label="Сумма оплаты"
                    value={formData.payment_amount}
                    onChange={handleChange('payment_amount')}
                    sx={{ mb: 2 }}
                />
                <TextField
                    fullWidth
                    type="date"
                    label="Дата запроса на оплату"
                    value={formData.payment_date_requested}
                    onChange={handleChange('payment_date_requested')}
                    InputLabelProps={{ shrink: true }}
                    sx={{ mb: 2 }}
                />
                <TextField
                    fullWidth
                    type="date"
                    label="Дата оплаты"
                    value={formData.payment_date_actual}
                    onChange={handleChange('payment_date_actual')}
                    InputLabelProps={{ shrink: true }}
                    sx={{ mb: 2 }}
                />
                <TextField
                    fullWidth
                    value={formData.currency}
                    onChange={handleChange('currency')}
                    InputLabelProps={{ shrink: true }}
                    sx={{ mb: 2 }}
                />
                <Box display="flex" justifyContent="space-between">
                    <Button variant="outlined" onClick={onClose}>
                        Отменить
                    </Button>
                    <Button variant="contained" color="primary" onClick={handleSubmit}>
                        {initialData ? 'Сохранить' : 'Создать'}
                    </Button>
                </Box>
            </Box>
        </Modal>
    );
};

export default InvoiceModal;
