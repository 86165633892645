import React, { useEffect, useState } from "react";
import { Modal, Box, Typography, TextField, Button } from "@mui/material";

interface EditPartModalProps {
    open: boolean;
    onClose: () => void;
    onSave: (partData: any) => void;
    initialData?: any;
    mode: 'create' | 'edit';
}

const EditPartModal: React.FC<EditPartModalProps> = ({ open, onClose, onSave, initialData, mode }) => {
    const [name, setName] = useState<string>("");
    const [description, setDescription] = useState<string>("");
    const [quantity, setQuantity] = useState<number>(1);

    useEffect(() => {
        if (open && initialData) {
            setName(initialData.name || "");
            setDescription(initialData.description || "");
            setQuantity(initialData.quantity || 1);
        } else {
            setName("");
            setDescription("");
            setQuantity(1);
        }
    }, [open, initialData]);

    const handleSave = () => {
        const payload = {
            ...initialData,
            name,
            description,
            quantity,
        };
        onSave(payload);
        onClose();
    };

    return (
        <Modal open={open} onClose={onClose}>
            <Box
                sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    width: 400,
                    bgcolor: "background.paper",
                    p: 3,
                    borderRadius: 2,
                }}
            >
                <Typography variant="h6" mb={2}>
                    {mode === "edit" ? "Редактировать часть заявки" : "Добавить новую часть заявки"}
                </Typography>
                <TextField
                    fullWidth
                    label="Название"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    margin="normal"
                />
                <TextField
                    fullWidth
                    label="Описание"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    margin="normal"
                />
                <TextField
                    fullWidth
                    label="Количество"
                    type="number"
                    value={quantity}
                    onChange={(e) => setQuantity(Number(e.target.value))}
                    margin="normal"
                />
                <Box display="flex" justifyContent="space-between" mt={3}>
                    <Button variant="outlined" onClick={onClose}>
                        Отмена
                    </Button>
                    <Button variant="contained" onClick={handleSave}>
                        Сохранить
                    </Button>
                </Box>
            </Box>
        </Modal>
    );
};

export default EditPartModal;
