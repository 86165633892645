import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {ICandidateOffer, ICreateCandidateOffer, IInvoices, IProviders, RegisterApi} from '../../api/registerApi';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Breadcrumbs,
    Button,
    Collapse,
    IconButton,
    Link,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import AddCardIcon from '@mui/icons-material/AddCard';
import CommentAcceptModal from "./modals/CommentAcceptModal";
import useModal from "../VehiclesPage/modals/useModal";
import {toast} from "react-toastify";
import UniversalDeleteModal from "./modals/UniversalDeleteModal";
import {useAppContext} from "../store/AppContext";
import CreateCandidateOffer from "./modals/CreateCandidateOffer";
import InvoiceModal from './modals/InvoiceModal';
import DocumentModal from "./modals/DocumentModal";
import AttachFileIcon from '@mui/icons-material/AttachFile';

const CandidateOffersPage = () => {
    const { id, partId } = useParams();
    const registerApi = new RegisterApi();
    const navigate = useNavigate();
    const [candidateOffers, setCandidateOffers] = useState<ICandidateOffer[]>([]);
    const [invoices, setInvoices] = useState<IInvoices[]>([]);
    const [partName, setPartName] = useState<string>('');
    const { isOpen, openModal, closeModal } = useModal();
    const {role} = useAppContext();
    const [providers,setProviders] = useState<IProviders[]>([]);
    // Single state for the selected offer
    const [selectedOffer, setSelectedOffer] = useState<ICandidateOffer | null>(null);
    const [selectedInvoice, setSelectedInvoice] = useState<IInvoices | null>(null);

    const [expandedOffer, setExpandedOffer] = useState(null);

    const handleToggleOffer = (offerId:any) => {
        setExpandedOffer(expandedOffer === offerId ? null : offerId);
    };

    const handleGetProviders = async ()=> {
        try {
            const data = await registerApi.getProviders();
            setProviders(data);
        } catch (e) {
            console.log(e);
        }
    }

    const handleGetCandidateOffers = async () => {
        try {
            const data = await registerApi.getCandidateOffers(id, partId);
            setCandidateOffers(data);
        } catch (error) {
            console.error('Failed to fetch candidate offers:', error);
        }
    };

    const handleGetPartById = async () => {
        try {
            const data = await registerApi.getApplicationPartById(Number(id), Number(partId));
            setPartName(data.name);
        } catch (e) {
            console.log(e);
        }
    };

    const openModalForApprove = (offer: ICandidateOffer) => {
        setSelectedOffer(offer);
        openModal('approve');
    };

    const openModalForEdit = (offer: ICandidateOffer) => {
        setSelectedOffer(offer);
        openModal('edit');
    };

    const openModalForDelete = (offer: ICandidateOffer) => {
        setSelectedOffer(offer);
        openModal('delete');
    };

    const handleApproveConfirm = async (comment: string) => {
        if (selectedOffer) {
            try {
                await registerApi.approveCandidateOffer(selectedOffer.id, comment,role as string, id, partId);
                closeModal('approve');
                toast.success('Предложение успешно одобрено!');
                setSelectedOffer(null);
                await handleGetCandidateOffers();
            } catch (e:any) {
                console.log(e)
                toast.error(e.response.data.detail);
            }
        }
    };

    const handleEditOffer = async (data: ICreateCandidateOffer) => {
        if(selectedOffer) {
            try {
                await registerApi.editCandidateOffer(selectedOffer.id, data, id, partId, role as string);
            } catch (e:any) {
                console.log(e);
                toast.error(e.response.data.detail);
            }
        }
    }

    const handleCreateCandidateOffer = async (data: ICreateCandidateOffer) => {
        try {
            await registerApi.createCandidateOffer(role as string, data, id, partId);
            toast.success('Предложение успешно добавлено!');
            void handleGetCandidateOffers();
        } catch (e) {
            console.log(e)
        }
    }

    const handleDeleteOffer = async () => {
        if(selectedOffer) {
            try {
                await registerApi.deleteCandidateOffer(selectedOffer.id, id, partId, role as string);
                closeModal('delete');
                toast.success('Предложение успешно удалено!');
                void handleGetCandidateOffers();
            } catch (e: any) {
                console.log(e);
                toast.error(e.response.data.detail)
            }
        }
    }

    const handleGetInvoices = async () => {
        try {
            const data = await registerApi.getInvoices(Number(id), Number(partId));
            setInvoices(data);
        } catch (e) {
            console.log(e);
        }
    }

    const handleInvoiceSubmit = async (data: {
        candidate_offer_id: number;
        invoice_number: string;
        invoice_title: string;
        payment_amount: number;
        currency: string;
        payment_date_requested: string;
        payment_date_actual: string;
    }) => {
        try {
            if (selectedInvoice) {
                await registerApi.editInvoice(Number(id), Number(partId), selectedInvoice.id, data, role as string);
                toast.success('Счет успешно обновлен!');
            } else {
                await registerApi.postInvoices(Number(id), Number(partId), data, role as string);
                toast.success('Счет успешно создан!');
            }
            closeModal('invoice')
            setSelectedInvoice(null);
            setSelectedOffer(null);
            await handleGetInvoices();
        } catch (e: any) {
            console.error(e);
            toast.error(e.response?.data?.detail || 'Произошла ошибка!');
        }
    };

    const handleDeleteInvoice = async () => {
        if(selectedInvoice) {
            try {
                await registerApi.deleteInvoice(Number(id), Number(partId), selectedInvoice.id, role as string);

                toast.success('Счет успешно удален!');
                void handleGetCandidateOffers();
            } catch (e: any) {
                console.log(e);
                toast.error(e.response.data.detail)
            } finally {
                closeModal('deleteInvoice');
            }
        }
    }
    // modal data
    const [documentData, setDocumentData] = useState<{
        id: number;
        type: string;
    } | null>(null);

    const handleOpenDocumentModal = async (id: number, type: string) => {
        try {
            const documents = await registerApi.getDocument(id, type);
            if (documents.length > 0) {
                setDocumentData({
                    id: id,
                    type: type,
                });
            } else {
                setDocumentData({
                    id: id,
                    type: type,
                });
            }
            openModal('document');
        } catch (e: any) {
            toast.error(e.response?.data?.detail || "Failed to fetch document");
            console.error(e);
        }
    };

    useEffect(() => {
        void handleGetCandidateOffers();
        void handleGetPartById();
        void handleGetProviders();
        void handleGetInvoices();
    }, []);

    return (
        <>
            <Box>
                <Box mb={2} display={'flex'} justifyContent={'space-between'}>
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link
                            underline="hover"
                            color="inherit"
                            onClick={() => navigate(`/register/${id}`)}
                            style={{ cursor: 'pointer' }}
                        >
                            {partName}
                        </Link>
                        <Typography color="text.primary">Предложения поставщиков</Typography>
                    </Breadcrumbs>
                    {role === 'Отдел закупок' &&
                    <Button onClick={() => openModal('create')}>
                        Добавить предложение
                    </Button>
                    }
                </Box>
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>#</TableCell>
                                <TableCell>Название</TableCell>
                                <TableCell>Статус</TableCell>
                                <TableCell>Сумма</TableCell>
                                <TableCell>Комментарии</TableCell>
                                <TableCell>Дата</TableCell>
                                <TableCell>Действия</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {candidateOffers.length !== 0 ? candidateOffers.map((offer, idx) => (
                                <>
                                <TableRow key={offer.id}>
                                    <TableCell>{idx + 1}</TableCell>
                                    <TableCell>{offer.provider_name}</TableCell>
                                    <TableCell>{offer.status}</TableCell>
                                    <TableCell>
                                        {offer.total_amount_kzt} {offer.currency}
                                    </TableCell>
                                    <TableCell>{offer.comments}</TableCell>
                                    <TableCell>{new Date(offer.created_at).toLocaleString()}</TableCell>
                                    <TableCell>
                                        <IconButton onClick={() => openModalForEdit(offer)} color="primary">
                                            <EditIcon />
                                        </IconButton>
                                        {role ==='Бухгалтерия' &&
                                        <IconButton onClick={() => {
                                            openModal('invoice');
                                            setSelectedOffer(offer);
                                        }}
                                            color="secondary">
                                            <AddCardIcon />
                                        </IconButton>
                                        }
                                        <IconButton onClick={() => openModalForDelete(offer)} color="secondary">
                                            <DeleteIcon />
                                        </IconButton>
                                        {role==='Отдел закупок' &&
                                            <IconButton onClick={() => openModalForApprove(offer)}>
	                                            <AssignmentTurnedInIcon color={offer.status === 'Одобрено' ? "success" : 'action'}/>
                                            </IconButton>
                                        }
                                        <IconButton onClick={() => handleOpenDocumentModal(offer.id, 'КП')}>
                                            <AttachFileIcon />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                                    <TableRow>
                                        <TableCell colSpan={7} style={{ padding: 0 }}>
                                            <Box>
                                                <Button
                                                    onClick={() => handleToggleOffer(offer.id)}
                                                    startIcon={<ExpandMoreIcon />}
                                                >
                                                    {expandedOffer === offer.id ? 'Скрыть счета' : 'Показать счета'}
                                                </Button>
                                                <Collapse in={expandedOffer === offer.id}>
                                                    {invoices.filter((invoice) => invoice.candidate_offer_id === offer.id).length > 0 ? (
                                                        <Table size="small">
                                                            <TableHead>
                                                                <TableRow>
                                                                    <TableCell>Номер счета</TableCell>
                                                                    <TableCell>Название счета</TableCell>
                                                                    <TableCell>Сумма оплаты</TableCell>
                                                                    <TableCell>Дата запроса на оплату</TableCell>
                                                                    <TableCell>Дата оплаты</TableCell>
                                                                    <TableCell>Статус</TableCell>
                                                                    <TableCell>Действия</TableCell>
                                                                </TableRow>
                                                            </TableHead>
                                                            <TableBody>
                                                                {invoices
                                                                    .filter((invoice) => invoice.candidate_offer_id === offer.id)
                                                                    .map((invoice) => (
                                                                        <TableRow key={invoice.id}>
                                                                            <TableCell>{invoice.invoice_number}</TableCell>
                                                                            <TableCell>{invoice.invoice_title}</TableCell>
                                                                            <TableCell>{invoice.payment_amount} {invoice.currency}</TableCell>
                                                                            <TableCell>{new Date(invoice.payment_date_requested).toLocaleDateString()}</TableCell>
                                                                            <TableCell>{new Date(invoice.payment_date_actual).toLocaleDateString()}</TableCell>
                                                                            <TableCell>{invoice.status}</TableCell>
                                                                            <TableCell>
                                                                                <Box display={'flex'}>
                                                                                    <IconButton onClick={() => {
                                                                                        openModal('invoice');
                                                                                        setSelectedInvoice(invoice);
                                                                                        setSelectedOffer(offer);
                                                                                    }}>
                                                                                        <EditIcon />
                                                                                    </IconButton>
                                                                                    <IconButton onClick={() => {
                                                                                        openModal('deleteInvoice');
                                                                                        setSelectedInvoice(invoice);
                                                                                    }}>
                                                                                        <DeleteIcon />
                                                                                    </IconButton>
                                                                                    <IconButton onClick={() => handleOpenDocumentModal(invoice.id, 'Счёт на оплату')}>
                                                                                        <AttachFileIcon />
                                                                                    </IconButton>
                                                                                </Box>
                                                                            </TableCell>
                                                                        </TableRow>
                                                                    ))}
                                                            </TableBody>
                                                        </Table>
                                                    ) : (
                                                        <Typography p={2} color="text.secondary">Счета не найдены</Typography>
                                                    )}
                                                </Collapse>
                                            </Box>
                                        </TableCell>
                                    </TableRow>
                                </>
                            )) :
                                <Typography p={2} color="text.secondary">Предложения не найдены</Typography>}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
            <CommentAcceptModal
                open={isOpen['approve']}
                onClose={() => {
                    closeModal('approve');
                    setSelectedOffer(null);
                }}
                onConfirm={handleApproveConfirm}
            />
            <UniversalDeleteModal
                open={isOpen['delete']}
                title={'Удалить поставщика ?'}
                content={`Вы действительно хотите удалить поставщика ? (${selectedOffer?.provider_name})`}
                onClose={() => {
                    closeModal('delete');
                    setSelectedOffer(null);
                }}
                onConfirm={handleDeleteOffer}
            />
            <UniversalDeleteModal
                open={isOpen['deleteInvoice']}
                title={'Удалить счет ?'}
                content={`Вы действительно хотите удалить счет ? (${selectedInvoice?.invoice_title})`}
                onClose={() => {
                    closeModal('deleteInvoice');
                    setSelectedInvoice(null);
                }}
                onConfirm={handleDeleteInvoice}
            />
            <CreateCandidateOffer
                open={isOpen['create'] || isOpen['edit']}
                onClose={() => {
                    closeModal(isOpen['create'] ? 'create' : 'edit');
                    setSelectedOffer(null);
                }}
                onSubmit={isOpen['create'] ? handleCreateCandidateOffer : handleEditOffer}
                providers={providers}
                initialData={
                    selectedOffer && isOpen['edit']
                        ? {
                            provider_id: selectedOffer.provider_id,
                            total_amount: selectedOffer.total_amount,
                            currency: selectedOffer.currency,
                            comments: selectedOffer.comments,
                        }
                        : undefined
                }
            />
            <InvoiceModal
                open={isOpen['invoice']}
                onClose={() => {
                    closeModal('invoice');
                    setSelectedInvoice(null);
                    setSelectedOffer(null);
                }}
                onSubmit={handleInvoiceSubmit}
                initialData={selectedInvoice as IInvoices}
                candidateOfferId={selectedOffer?.id || 0}
            />
            <DocumentModal
                isOpen={isOpen['document']}
                onClose={() => {
                    closeModal('document');
                    setDocumentData(null);
                }}
                documentData={documentData}
            />
        </>
    );
};

export default CandidateOffersPage;
