import React from "react";
import {
    Box,
    Button,
    Typography,
    Modal,
} from "@mui/material";

interface DeleteConfirmationModalProps {
    open: boolean;
    onClose: () => void;
    onDelete: () => void; // Function to handle deletion
    itemName?: string; // Optional name of the item to be deleted
}

const DeleteConfirmationModal: React.FC<DeleteConfirmationModalProps> = ({
                                                                             open,
                                                                             onClose,
                                                                             onDelete,
                                                                             itemName,
                                                                         }) => {
    return (
        <Modal open={open} onClose={onClose}>
            <Box
                sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    bgcolor: "background.paper",
                    borderRadius: 4,
                    boxShadow: 24,
                    p: 4,
                    width: "400px",
                    textAlign: "center",
                }}
            >
                <Typography variant="h6" mb={2}>
                    Подтвердите удаление
                </Typography>
                <Typography variant="body1" mb={3}>
                    {itemName
                        ? `Вы уверены, что хотите удалить "${itemName}"?`
                        : "Вы уверены, что хотите удалить этот элемент?"}
                </Typography>
                <Box display="flex" justifyContent="space-between">
                    <Button onClick={onClose} color="secondary" variant="outlined">
                        Отмена
                    </Button>
                    <Button onClick={onDelete} color="error" variant="contained">
                        Удалить
                    </Button>
                </Box>
            </Box>
        </Modal>
    );
};

export default DeleteConfirmationModal;
