import React from "react";
import {Box, Typography} from "@mui/material";
import {roles} from "./RoleSelection";

interface ProgressBarProps {
    stage: string;
}

const ProgressBar: React.FC<ProgressBarProps> = ({ stage }) => {
    const currentIndex = roles.findIndex((role) => role.label === stage || role.key === stage);
    return (
        <Box display="flex" flexDirection={"column"} justifyContent="center" alignItems="center" gap={0.5}>
            <Typography variant="body2">
                {stage}
            </Typography>
            <Box display="flex" justifyContent="center" alignItems="center" gap={0.5}>
            {roles.map((_, index) => (
                <Box
                    key={index}
                    sx={{
                        width: "12px",
                        height: "12px",
                        borderRadius: "4px",
                        backgroundColor: index <= currentIndex ? "#1976d2" : "#e0e0e0", // Highlight completed stages
                    }}
                >
                </Box>
            ))}
            </Box>
        </Box>
    );
};

export default ProgressBar;